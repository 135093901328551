import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ArrowIcon = ({ collapsed, className, fill, stroke }) => {
  return (
    <svg
      data-testid="arrow-icon"
      className={classNames('arrowIcon h-6 w-6', className)}
      viewBox="0 0 30 31"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-label="arrow"
    >
      <g>
        <path
          d="M22 19.25L14.5 11.75L7 19.25"
          stroke={stroke}
          transform={collapsed ? '' : 'translate(30 30.5) rotate(180)'}
        />
      </g>
      <defs>
        <clipPath>
          <rect fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

ArrowIcon.defaultProps = {
  collapsed: true,
  fill: 'none',
  stroke: 'black',
}

ArrowIcon.propTypes = {
  collapsed: PropTypes.bool,
  className: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
}

export default React.memo(ArrowIcon)

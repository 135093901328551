import { APP_BASE_PATH } from '../../../../common/constants'

const TITLE = 'FAQs | Freedom Debt Relief'
const DESCRIPTION =
  'Browse our FAQs to learn about how Freedom Debt Relief works, debt relief fees, how to find a legit debt relief company, and more.'
const URL = `${APP_BASE_PATH()}`

export const faqData = {
  seo: {
    title: TITLE,
    description: DESCRIPTION,
    canonical: URL,
    openGraph: {
      title: TITLE,
      description: DESCRIPTION,
      url: URL,
      locale: 'en_US',
      type: 'article',
      article: {
        modifiedTime: '2020-05-12T17:01:10+00:00',
        authors: ['https://www.facebook.com/freedomdebtrelief'],
      },
      images: [
        {
          url: `${APP_BASE_PATH()}/next-assets/brand-pages/heros/faq.webp`,
        },
      ],
    },
  },
}

export const faqs = [
  // Question 1
  {
    title: 'Can I trust Freedom Debt Relief?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'As a trusted leader in debt settlements in America, we’re proud to help Americans like you get rid of debt and get back to their lives. Since 2002, Freedom Debt Relief has served over 1 million Americans and settled over $18 billion in debt. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Industry recognized and certified',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'AADR leader. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                'As a member of the American Association for Debt Resolution (AADR), Freedom Debt Relief is held to the highest standards in the debt settlement industry. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'IAPDA certified. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                'We’re a platinum member of the International Association of Professional Debt Arbitrators (IAPDA) and our certified team works with over 3,500 creditors.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Don’t just take our word for it. Explore our customer reviews',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'We’re most proud when our customers share their experience with Freedom Debt Relief and how we’ve helped them with their debt. We’ve earned recognition and awards through our excellent customer service and commitment to transparency. Read more ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.freedomdebtrelief.com/testimonials/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'client reviews & testimonials',
                  marks: [
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value: '. \n',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'unordered-list',
          data: {},
          content: [
            {
              nodeType: 'list-item',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Consumer Affairs - average rating of 4.5/5, based on 32,000+ reviews',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'unordered-list',
                  data: {},
                  content: [
                    {
                      nodeType: 'list-item',
                      data: {},
                      content: [
                        {
                          nodeType: 'paragraph',
                          data: {},
                          content: [
                            {
                              nodeType: 'text',
                              value:
                                'Best Experience with Staff - Debt Settlement (2024) - ConsumerAffairs',
                              marks: [],
                              data: {},
                            },
                          ],
                        },
                      ],
                    },
                    {
                      nodeType: 'list-item',
                      data: {},
                      content: [
                        {
                          nodeType: 'paragraph',
                          data: {},
                          content: [
                            {
                              nodeType: 'text',
                              value:
                                'Best Customer Service - Debt Settlement (2024) - ConsumerAffairs',
                              marks: [],
                              data: {},
                            },
                          ],
                        },
                      ],
                    },
                    {
                      nodeType: 'list-item',
                      data: {},
                      content: [
                        {
                          nodeType: 'paragraph',
                          data: {},
                          content: [
                            {
                              nodeType: 'text',
                              value:
                                'Best for Transparency - Debt Settlement (2024) - Consumer Affairs',
                              marks: [],
                              data: {},
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              nodeType: 'list-item',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'TrustPilot rating of 4.6/5, based on 43,000+ reviews',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
            {
              nodeType: 'list-item',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Best Company rating of 4.8/5, based on 17,000+ reviews',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
            {
              nodeType: 'list-item',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'A+ rating on Better Business Bureau',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
            {
              nodeType: 'list-item',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Featured in Yahoo! Finance, USA Today, MSN, Motley Fool, CNBC',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
  // Question 2
  {
    title: 'What services do you provide to reduce debt?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Freedom Debt Relief and our affiliates offer comprehensive debt solutions—from debt relief to personal loans to debt consolidation—that are customized to reduce your debt in a way that fits your budget and goals. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Compare your debt relief options with a free debt evaluation',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'We understand that your debt situation is unique and needs a solution built just for you to best fit your needs. That’s why we offer a free debt evaluation that looks at all your available options so you have a clear path to get rid of debt. We’ll help you find the best fit with us or one of our partners.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Debt expert and legal support',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'We have a team of Certified Debt Consultants, Debt Negotiators, and Client Services Reps to help you along your debt-free journey. We also offer access to the Legal Partner Network for additional debt negotiation support should you need it.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '\nSee more information about ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.freedomdebtrelief.com/why-were-better/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'why clients choose us',
                  marks: [
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value: ' to help them put debt behind them. ',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
  // Question 3
  {
    title: 'Does Freedom Debt Relief offer debt consolidation loans?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Yes, Freedom Debt Relief has affiliated partners that could offer ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.freedomdebtrelief.com/debt-consolidation/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'debt consolidation loans',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value:
                '. Loans tend to have higher credit requirements and a debt relief program has key differences and offers several advantages over consolidating debt through a loan. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Easier to qualify than a loan.',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                ' There may be higher credit requirements to qualify for a debt consolidation or personal loan. You don’t need good credit to enroll in Freedom Debt Relief. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Reduces what you owe.',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                ' A debt relief program could help you pay less on your total debt unlike a consolidation loan.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'No added interest.',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                ' Since a debt relief program doesn’t involve a loan, you don’t payinterest to Freedom Debt Relief like you would with a loan. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Still interested in a loan to consolidate your debt? You could pre-qualify through Freedom Debt Relief. Our partner loan affiliate can check if you could qualify for a debt consolidation or personal loan or if there are options that are a better fit. ',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
  // Question 4
  {
    title: 'How does a debt relief program like Freedom Debt Relief work?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Freedom Debt Relief offers a smart way to help you pay less on debt in as little as 24-48 months. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                '1. In our debt relief program, you’ll make one low monthly deposit into your Dedicated Account, and these deposits will be used to fund settlements to your creditors. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                '2. Once enough funds are built up in your Dedicated Account, our expert team negotiates with your creditors to settle debt for less than you owe.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                '3. When we reach a settlement, we send the offer to you to approve. Once approved, payments are sent to your creditor from your Dedicated Account to resolve your debt. Freedom Debt Relief will also process our program fee at this time.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Learn more about ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'hyperlink',
              data: {
                uri: 'https://www.freedomdebtrelief.com/how-it-works/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'how debt relief works',
                  marks: [
                    {
                      type: 'underline',
                    },
                  ],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value: ' to help you get rid of debt. ',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
  // Question 5
  {
    title: 'Is debt relief an affordable option and can I qualify?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'A debt relief program can be a good option as you could resolve your debt for less than you owe and in less time than making minimum payments. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Easy to get started. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'Unlike a consolidation loan or personal loan, ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'you don’t need good credit to qualify',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                ' for Freedom Debt Relief. There are no upfront fees to enroll in the program.',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Get a free debt evaluation. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                'A Certified Debt Consultant can help you go through your options, from debt consolidation or personal loans to credit counseling and more. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Choose a plan that fits your budget. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                'Once you enroll your debts into the program, you could get a plan to move on from debt with one low monthly payment. ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Only pay us when we deliver results. ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'We’re confident we could help you to succeed in reducing debt for less than you owe. That’s why we offer a money-back program guarantee**. And we only charge settlement fees after you approve your settlement and the first payment has been made.',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
  // Question 6
  {
    title: 'What type of debt can I enroll in a debt relief program?',
    content: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Our debt resolution program could help you with unsecured debts (debt not backed by collateral like a house or car). ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Debts we could help with ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Credit or retail store cards ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Medical debt ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Most personal loans ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Collections or repossessions ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Lines of credit ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Some payday loans ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Some private student loans ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Debts we can’t help with',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Taxes ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Utility bills ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Lawsuits ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Secured loans (HELOC, auto, etc.) ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '- Federal student loans ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Still not sure if your debt is eligible for Freedom Debt Relief? We’ll help you figure it out : Call 800-910-0065.',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
  },
]

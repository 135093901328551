import React, { useState, useCallback, useEffect } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { FAQPageJsonLd } from 'next-seo'
import { useAnalytics } from '../../../../common/analytics'
import { useIntersectionObserver } from '../../../../common/hooks/useIntersectionObserver'
import { jsonLdData } from '../../../../common/constants/jsonLd'
import { faqData, faqs } from './consts'
import CustomJsonLd from '../../../shared/CustomJsonLd'
import Accordion from './Accordion'

const sectionId = 'faq'

const Faqs = () => {
  const { seo } = faqData
  const [faqItems] = useState(faqs)
  const { track } = useAnalytics()

  const { ref: faqsRef } = useIntersectionObserver({
    threshold: 0.5,
    freezeOnceVisible: true,
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        const data = {
          additional_info: { section: sectionId },
          nav_link_section: 'Faqs Page',
          click_type: 'Section View',
          click_id: 'Debt relief FAQs',
          click_url: '/',
          click_text: 'FDR-Web | Debt relief FAQs',
          track_event: 'section_view',
          event_type: 'track',
        }

        track(data, {}, 'section_view')
      }
    },
  })

  const JsonLdInfo = faqs.map((item) => ({
    questionName: item.title,
    acceptedAnswerText: documentToHtmlString(item.content).replace(/"/g, "'"),
  }))

  const handleLinkClick = useCallback(() => {
    const data = {
      nav_link_section: 'Faqs Page',
      click_type: 'Faq Link Click',
      click_id: 'See all debt relief FAQs',
      click_url: '/faq',
      click_text: 'FDR-Web | See all debt relief FAQs',
      track_event: 'link_click',
      event_type: 'track',
    }

    track(data, {}, 'click')
  }, [track])

  return (
    <div className="w-content lg:px-32" id={sectionId}>
      <FAQPageJsonLd mainEntity={JsonLdInfo} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(seo?.title, seo?.description, '/')}
      />
      <section
        ref={faqsRef}
        className="m-auto max-w-screen-lg py-2 pb-10 lg:py-12"
      >
        <Accordion
          items={faqItems}
          title="Debt relief FAQs"
          titleCenter
          questionClassName="text-[18px] lg:text-[24px] font-bold leading-[23.4px] lg:leading-8"
          answerClassName="text-[14px] lg:text-[16px] text-balance font-normal leading-[19.6px] lg:leading-6"
          titleClassName="text-[24px] lg:text-[36px] font-extrabold leading-[31.2px] lg:leading-10"
        />
        <div className="px-5 lg:px-2" onClick={handleLinkClick}>
          <a
            className="text-[16px] font-bold leading-7 text-blue-600 lg:text-[18px] "
            href="https://www.freedomdebtrelief.com/faq"
          >
            See all debt relief FAQs
          </a>
        </div>
      </section>
    </div>
  )
}

Faqs.defaultProps = {}

Faqs.propTypes = {}

export default Faqs

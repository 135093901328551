import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import RichText from '../../../../../shared/RichText'
import { generateElementId } from '../../../../../../common/helpers'
import classNames from 'classnames'
import ArrowIcon from 'src/components/shared/ArrowIcon'

const AccordionItem = ({
  title,
  content,
  opened,
  id,
  changeCollapse,
  showFirstDivider,
  questionClassName,
  answerClassName,
  iconClassName,
}) => {
  const handleClick = useCallback(
    () => changeCollapse(id),
    [changeCollapse, id]
  )

  const showTitle = (index) => {
    if (index < 2) {
      return (
        <h2
          className={classNames(
            'select-none break-words p-0 pr-12 text-blue-600 transition-all duration-500 lg:mt-0 lg:py-4.5',
            questionClassName,
            opened && 'pb-1 lg:pb-4'
          )}
          id={generateElementId(title)}
        >
          {title}
        </h2>
      )
    }

    return (
      <div
        className={classNames(
          'select-none break-words p-0 pr-12 text-blue-600 transition-all duration-500 lg:mt-0 lg:py-4.5',
          questionClassName,
          opened && 'pb-1 lg:pb-4'
        )}
        id={generateElementId(title)}
      >
        {title}
      </div>
    )
  }

  return (
    <div
      data-testid="accordion-item"
      className={classNames(
        !showFirstDivider && ' first:border-0',
        'border-t border-gray-540'
      )}
    >
      <div
        className={classNames(
          'relative flex cursor-pointer flex-col justify-between py-5.4 lg:flex-row lg:items-center lg:p-0',
          opened && 'pb-5'
        )}
        onClick={handleClick}
        data-testid={`title_${id}`}
      >
        {title ? showTitle(id) : null}
        <ArrowIcon
          className={classNames(
            'h-5.6 absolute right-0 w-5.5 text-blue-600',
            iconClassName,
            opened && 'lg:mt-2'
          )}
          collapsed={opened}
          stroke={opened ? 'rgb(4 27 147)' : 'black'}
          data-testid="arrow-icon"
        />
      </div>
      <div
        data-testid="content-wrapper"
        className={`${styles.accordionContent} ${
          !opened ? styles.collapsed : ''
        }`}
      >
        <div data-testid="content" className="text-black mb-4 break-words">
          <RichText className={answerClassName} contentData={content} />
        </div>
      </div>
    </div>
  )
}

AccordionItem.defaultProps = {
  opened: false,
  mobileRow: false,
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  id: PropTypes.number.isRequired,
  changeCollapse: PropTypes.func.isRequired,
  mobileRow: PropTypes.bool,
  showFirstDivider: PropTypes.bool,
  questionClassName: PropTypes.string,
  answerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
}

export default AccordionItem

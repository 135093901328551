import { useEffect, useRef, useState } from 'react'

const defaultOptions = {
  threshold: 0,
  root: null,
  rootMargin: '0%',
  freezeOnceVisible: false,
  initialIsIntersecting: false,
  onChange: null,
}
export function useIntersectionObserver(options = {}) {
  const mergedOptions = { ...defaultOptions, ...options }
  const [ref, setRef] = useState(null)
  const {
    threshold,
    root,
    rootMargin,
    freezeOnceVisible,
    initialIsIntersecting,
    onChange,
  } = mergedOptions
  const [state, setState] = useState(() => ({
    isIntersecting: initialIsIntersecting,
    entry: undefined,
  }))

  const callbackRef = useRef(onChange)

  const frozen = state.entry?.isIntersecting && freezeOnceVisible

  useEffect(() => {
    if (!ref || !('IntersectionObserver' in window) || frozen) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isIntersecting =
            entry.isIntersecting &&
            (Array.isArray(observer.thresholds)
              ? observer.thresholds
              : [observer.thresholds]
            ).some((threshold) => entry.intersectionRatio >= threshold)

          setState({ isIntersecting, entry })

          if (callbackRef.current) {
            callbackRef.current(isIntersecting, entry)
          }
        })
      },
      { threshold, root, rootMargin }
    )
    observer.observe(ref)

    return () => {
      observer.disconnect()
    }
  }, [ref, threshold, root, rootMargin, frozen, freezeOnceVisible])

  useEffect(() => {
    if (ref || !state.entry?.target || freezeOnceVisible || frozen) return

    setState({ isIntersecting: initialIsIntersecting, entry: undefined })
  }, [ref, state.entry, freezeOnceVisible, frozen, initialIsIntersecting])

  const result = [setRef, !!state.isIntersecting, state.entry]

  result.ref = result[0]
  result.isIntersecting = result[1]
  result.entry = result[2]

  return result
}

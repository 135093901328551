export const jsonLdData = (
  metaTitle,
  metaDescription,
  pageSlug,
  location = 'en-US'
) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    '@id': `https://www.freedomdebtrelief.com${pageSlug}#webpage`,
    name: metaTitle,
    url: `https://www.freedomdebtrelief.com${pageSlug}`,
    inLanguage: location,
    description: metaDescription,
  }
}
